<template v-if="sahabat">
  <v-card
    class="mx-auto"
  >
    <v-img
      src="@/assets/sld.jpg"
      contain
    />
    <br>
    <div class="headline white--text mb-3 text-xs-center">
      JOM jadi sebahagian daripada kami dan nikmati ganjaran yang menarik!
    </div>
    <v-spacer />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="success"
        variant="text"
        @click="sldDialog = true"
      >
        Daftar Sebagai Pengedar
      </v-btn>
      <v-btn
        color="success"
        variant="text"
        @click="sldUser()"
      >
        Info lanjut
      </v-btn>
      <v-spacer />
      <!-- <v-btn
        @click="show = !show"
        flat
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn> -->
    </v-card-actions>

    <!-- <v-expand-transition>
      <div v-show="show">
        <v-divider />

        <v-card-text>
          <div>Terbuka PERCUMA secara automatik kepada pengguna Black baharu dan sedia ada.</div>
          <div>Tidak termasuk B10 & Supp Line OneFamily.</div>
        </v-card-text>
      </div>
    </v-expand-transition> -->
    <!-- <v-dialog
      v-model="sldDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-alert
          :value="true"
          type="info"
        >
          Dengan tekan DAFTAR anda akan menjadi pengedar sah ONEXOX.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            flat
            @click="close"
          >
            Kembali
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click="submit()"
          >
            Daftar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="sldDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Daftar sebagai pengedar SLD</span>
        </v-card-title>

        <v-alert
          :value="true"
          :type="executeAlertType"
          v-html="executeAlertMessage"
        />
        <v-card-text
          v-show="executeAlertType !== 'success'"
        >
          <v-text-field
            v-model="name"
            label="Name"
            readonly
          />
          <v-text-field
            v-model="msisdn"
            label="Msisdn"
            readonly
          />
          <v-text-field
            v-model="black"
            label="Plan"
            readonly
          />
          <v-text-field
            v-model="mykad"
            label="MyKad"
            readonly
          />
          <v-text-field
            v-model="street"
            label="Street"
            readonly
          />
          <v-text-field
            v-model="city"
            label="City"
            readonly
          />
          <v-text-field
            v-model="stateName"
            label="State"
            readonly
          />
          <v-text-field
            v-model="email"
            label="Email"
            readonly
          />

          <template>
            <v-container fluid>
              <v-checkbox
                v-model="checkbox"
                :true-value="1"
                :false-value="0"
                :rules="[v => !!v || 'Anda mesti bersetuju untuk meneruskan!']"
              >
                <template #label>
                  <div>
                    Saya bersetuju dengan
                    <v-tooltip location="bottom">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          color="primary"
                          flat
                          @click="term = true"
                        >
                          Terma dan syarat
                        </v-btn>
                      </template>
                      Buka terma dan syarat
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-container>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click="close()"
          >
            {{ executeAlertType === 'info' ? 'Kembali' : 'Tutup' }}
          </v-btn>

          <v-btn
            v-show="executeAlertType !== 'success'"
            color="blue darken-1"
            flat
            @click="submit()"
          >
            Daftar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="term"
      persistent
      max-width="350px"
    >
      <v-card>
        <v-card-title class="headline">
          Terma and Syarat
        </v-card-title>
        <v-card-text>Saya mengaku bahawa semua maklumat di atas adalah lengkap dan benar. Sekiranya kenyataan yang diberikan tidak benar atau tidak lengkap, ONE XOX Sdn Bhd  berhak untuk membatalkan pendaftaran ini.</v-card-text>
        <tr>
          <v-card-text>Dengan meneruskan pendaftaran ini, saya bersetuju dengan semua terma & syarat selaku pengedar Single Level Dealership (SLD) dan segala aktiviti saya selaku pengedar ONE XOX Sdn Bhd adalah tertakluk kepada garis panduan yang ditetapkan oleh syarikat dan Suruhanjaya Komunikasi & Multimedia (SKMM).</v-card-text>
        </tr>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            flat
            @click="term = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="alertColor"
      :timeout="3000"
      top
    >
      {{ alertText }}
      <v-btn
        dark
        icon
        floating
        small
        flat
        @click="snackbar = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<!-- <style>
 .card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width : 300%;
}
.text--primary {
  justify-content: center;
  align-items: center;
}
.card-actions {
  position: absolute;
  bottom: 0px;
}
</style> -->
<script>

import { createGetParams, createPostParams } from '@/rest'
export default {
  data () {
    return {
      name: null,
      msisdn: null,
      mykad: null,
      black: null,
      street: null,
      stateName: null,
      city: null,
      email: null,
      term: false,
      checkbox: false,
      alertColor: 'success',
      dialog: false,
      snackbar: false,
      sldDialog: false,
      show: false,
      alertText: '',
      executeAlertType: 'info',
      executeAlertMessage: null,
    }
  },
  mounted () {
    const params = createGetParams({
      profiling: true,
    })

    this.$rest.get('getProfile.php', params)
      .then(response => {
        this.name = response.data.name
        this.msisdn = response.data.msisdn
        this.mykad = response.data.id
        this.black = response.data.black
        this.street = response.data.street
        this.stateName = response.data.stateName
        this.city = response.data.city
        this.email = response.data.email
      })
  },
  watch: {
    sldDialog () {
      this.executeAlertType = 'info'
      this.executeAlertMessage = '<div>Tekan <strong>Daftar</strong> untuk menjadi pengedar ONEXOX</div>'
    },
  },
  methods: {
    close () {
      this.sldDialog = false
      location.reload()
    },

    sldUser: function () {
      const path = '/user_sld'
      this.$router.push({
        path,
      })
    },
    async submit () {
      const params = createPostParams({
        action: 'appointDealer',
        payload: {
          sahabat: 'Y',
          type: 'SLD',
        },
      })

      if (this.checkbox === 1) {
        try {
          await this.$rest.post('postAction.php', params)
          this.$nextTick(() => {
            this.executeAlertType = 'success'
            this.executeAlertMessage = 'Anda telah berjaya menjadi pengedar ONEXOX'
          })
        } catch (e) {
          this.$nextTick(() => {
            this.executeAlertType = 'error'
            this.executeAlertMessage = e.message + ': ' + e.response.data
          })
        } finally {
          this.loading = false
        }
      } else {
        this.executeAlertType = 'error'
        this.executeAlertMessage = 'Anda mesti bersetuju terma dan syarat untuk meneruskan!'
      }
    },
  },
}
</script>
